// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/modules/location/config.ts"
);
import.meta.hot.lastModified = "1730916919147.2085";
}
// REMIX HMR END

export const config = {
  path: '/dashboard/settings/locations',
  apiPath: '/api/location',
}
